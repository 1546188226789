<!--设备模块--时间表控制组设置页-->
<template>
  <div id="controllGroup" ref="controllGroup">
    <div class="aside">
      <a-spin :spinning="spinning" style="width: 100%; height: 100%">
        <my-navder
          :list="list"
          @select="getMsgFormSon"
          :style="style"
        ></my-navder>
      </a-spin> 
    </div>
    <div class="right">
      <a-spin :spinning="loading" class="main-spinning" v-if="id">
        <div class="main">
          <my-headertitle>{{$t('controllGroup.a1')}}</my-headertitle>
          <div class="info">
            <my-nameupdate
              :title="nodeParentName"
              :name="nodeName"
              @confirm="nameUpdate"
              :state="state"
              :loading="loadingOK"
              ref="children"
            ></my-nameupdate>
            <div>
              <span>{{$t('controllGroup.a2')}}</span>
              <span>{{ nodeId }}</span>
            </div>
          </div>
          <my-tabletitle>{{$t('controllGroup.a3')}}</my-tabletitle>
          <my-table :data="data"></my-table>
        </div>
      </a-spin>
      <div class="main-show" v-else>
        <a-icon type="select" style="font-size: 60px; margin-bottom: 20px" />
        <span>{{$t('controllGroup.a4')}}</span>
      </div>
      <div class="footer" v-if="id">
        <!-- <a-button type="primary" @click="copy">时间表复制</a-button> -->
        <a-button type="primary" @click="toTerminalEditor"
          >{{$t('controllGroup.a5')}}</a-button
        >
      </div>
    </div>
    <a-modal
      :title="$t('controllGroup.a6')"
      :width="400"
      centered
      :visible="visible"
      :closable="true"
      :maskClosable="false"
      :confirm-loading="confirmLoading"
      :getContainer="() => this.$refs.trafficpatterns"
      @ok="allHandleOk"
      @cancel="allHandleCancel"
    >
      <div style="padding-bottom: 10px">
        <span>{{$t('controllGroup.a7')}}</span>
      </div>
      <my-select
        style="width: 150px"
        :list="arr"
        :placeholder="$t('controllGroup.a4')"
        @select="selectCopyKey"
        :isActive="isActive"
      ></my-select>
    </a-modal>
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import navdar from "../../components/Navdar/vertical";
import headerTitle from "../../components/Title/headerTitle";
import deviceTable from "./deviceTable";
import nameUpdate from "../../components/Name/nameUpdate";
import { lightTimetableListKT,getLightTimetableData,updateScheduleName } from "../../api/device";
import select from "../../components/Select/select";

export default {
  data() {
    return {
      list: [],
      arr: [],
      MAX_NUM: 1,
      timer: null,
      carriedOut: true,
      pos: 0,
      spinning: false,
      id: undefined,//包含类型和时间表ID
      loading: false,
      schType: 9,
      nodeId: undefined,//时间表ID
      nodeName: undefined,//时间表名称
      nodeParentName: undefined,//时间表父节点名称
      data: [],
      visible: false,
      confirmLoading: false,
      copyKey: undefined,
      state: false,
      isActive: undefined,
      loadingOK: false,
    };
  },
  // 获取侧边栏数据同时执行虚拟化列表的方法
  async mounted() {
    this.spinning = true;
    // 进入页面时调用获取页面左侧列表方法
    this.navderList();    
    this.spinning = false;
  },
  computed: {
    // 侧边栏的高度计算和padding-top计算
    style: function () {
      if (this.pos !== 0) {
        return `height:${48 * this.arr.length}px;padding-top:${
          this.pos * 48 - 96
        }px`;
      } else {
        return `height:${48 * this.arr.length}px;padding-top:0px`;
      }
    },
  },
  watch: {
    // 监听侧边栏选择的id，并加载页面数据
    id: function (newQuestion, oldQuestion) {   
      
      this.getdata();
    },
    p1: function () {
      this.selectList = this.arr.slice(this.p1, this.p2);
    },
    '$route'(to,from){
      this.spinning = true;
      // 进入页面时调用获取页面左侧列表方法
      this.navderList();    
      this.spinning = false;
    },
  },
  //缓存页面勾子函数
  activated(){
    this.getdata();
  },
  methods: {
    // 获取页面左侧列表方法
    navderList() {
      this.spinning = true;
      let name = this.$route.name;
      if(name=="VRFAC_FAC_SCEDULE_GR" || name=="LIGHTING_FAC_SCEDULE_GR"||name=="ENVIRONMENT_FAC_SCEDULE_GR"||name=="BSDSAC_FAC_SCEDULE_GR"||name=="FACILITIES_FAC_SCEDULE_GR" || name=="ENE_SCEDULE_GR")
        this.schType=9;
      else if(name=="VRFAC_FAC_KIKIRENDOU_GR" || name=="LIGHTING_FAC_KIKIRENDOU_GR"||name=="ENVIRONMENT_FAC_KIKIRENDOU_GR"||name=="BSDSAC_FAC_KIKIRENDOU_GR"||name=="FACILITIES_FAC_KIKIRENDOU_GR")
        this.schType=6;
      console.log("timeTable--获取数据：",this.schType);

      let par = {
        siteId: this.$route.query.id,
        schType: this.schType,
      };
      lightTimetableListKT(par)
        .then((res) => {
          this.spinning = false;
          let { data } = res;
          data.key = "sub1";
          this.list.length=0;
          this.list.push(data);          
        })
        .catch((err) => {
          this.spinning = false;
          console.log(err);
        });
    },
    // 加载页面数据的方法    
    getdata() {
      console.log("query>>>>",this.nodeId);
      if(!this.nodeId)
        return;
      this.loading = true;
      let par = {
        bacsScheduleId: this.nodeId,
      };   
      this.data = new Array();   
      getLightTimetableData(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("getLightTimetableData--data",data);
          for (let i = 0; i < data.length; i++) {
            data[i].key = i + 1;
          }
          this.data = data;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },    
    // 点击侧边栏获取页面详细数据加载id
    getMsgFormSon(data) {
      this.id = data;
      let number = this.id.split(",");
      this.nodeId = number[1];
      let schedule = this.list[0].children[0].children;
      this.nodeParentName = this.list[0].children[0].title;
      for(var i=0;i<schedule.length;i++){
          if(this.id == schedule[i].key){
            this.nodeName = schedule[i].title;
            break;
          }
      }
      console.log("timeTable--获取选择时间表名称：",this.nodeParentName);      
    },
    // 虚拟化侧边栏列表
    virtual() {
      if (this.carriedOut) {
        let height = this.$refs.container.clientHeight;
        this.MAX_NUM = Math.ceil(height / 48);
        let scrollTop = this.$refs.container.scrollTop;
        this.pos = Math.round(scrollTop / 48);
        console.log("TimeTable-virtual",this.list);
        this.list[0].children[0].children = this.arr.slice(
          this.pos,
          this.pos + this.MAX_NUM
        );
        this.carriedOut = false;
        this.timer = setTimeout(() => {
          this.carriedOut = true;
          clearTimeout(this.timer);
        }, 50);
      }
    },
    // 通行模式页跳转至认证终端编辑页
    toTerminalEditor() {
      let singnalType = 17;
      let path = '';
      if(this.$route.name.indexOf("BSDSAC_")>=0) {
        path = "/homepage/air-conditionwater/terminalEditor";
      }
      else if(this.$route.name.indexOf("VRFAC_")>=0) {
        path = "/homepage/air-condition/terminalEditor";
      }
      else if(this.$route.name.indexOf("LIGHTING_")>=0) {
        path = "/homepage/light/terminalEditor";
      }
      else if(this.$route.name.indexOf("ENVIRONMENT_")>=0) {
        path = "/homepage/environment/terminalEditor";
      }  
      else if(this.$route.name.indexOf("FACILITIES_")>=0) {
        path = "/homepage/facility/terminalEditor";
      } else if(this.$route.name.indexOf("ENE_")>=0) {
        path = "/homepage/facility/terminalEditor";
      }  else if(this.$route.name.indexOf("INFO_")>=0) {
        path = "/homepage/facility/terminalEditor";
      }       
      this.$router.push({        
        path: path,
        // query: { id: this.$route.query.id, bacsScheduleId: this.bacsScheduleId,bacsScheduleName:this.bacsScheduleName },
        query: { id: this.$route.query.id, nodeId: this.nodeId,schType:this.schType,type:singnalType,
                nodeName:this.nodeName },
      });
    },
    // 通行模式复制按钮
    copy() {
      this.visible = true;
    },
    // 通行模式复制的确定按钮
    allHandleOk() {
      if (this.isActive == undefined) {
        this.$error({
          title: this.$t('controllGroup.a8'),
          content: this.$t('controllGroup.a9'),
          centered: true,
        });
      } else {
        this.confirmLoading = true;
        let data = {
          srcID: this.copyKey,
          targetID: this.accessRoleId,
        };
        console.log(data);
        passCopy(data)
          .then((res) => {
            console.log(res);
            this.confirmLoading = false;
            if (res.errorCode == "00") {
              this.visible = false;
              this.$message.success(res.msg);
              this.copyKey = undefined;
              let data = {
                siteId: this.$route.query.id,
                accessRoleId: this.accessRoleId,
              };
              this.getdata(data);
            } else if (res.errorCode == "02") {
              this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            this.confirmLoading = false;
            console.log(err);
          });
      }
    },
    // 通行模式复制的取消按钮
    allHandleCancel() {
      this.visible = false;
      this.isActive = undefined;
    },
    // 选择通行模式 accessRoleList
    selectCopyKey(val) {
      this.isActive = val;
      this.copyKey = val.key.split(",")[1];
    },
    // 更新名称
    nameUpdate(name) {
      this.loadingOK = true;
      let par = {
        scheId: this.nodeId,
        scheType: this.schType,
        newName:name,
      };
      console.log(par);
      updateScheduleName(par)
        .then((res) => {
          this.loadingOK = false;
          this.$refs.children.changestate();
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-navder": navdar,
    "my-headertitle": headerTitle,
    "my-table": deviceTable,
    "my-nameupdate": nameUpdate,
    "my-select": select,
  },
};
</script>
<style scoped>
#controllGroup {
  height: 100%;
  display: flex;
}
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
.right {
  width: calc(100% - 250px);
  display: flex;
  flex-direction: column;
}
.aside {
  width: 250px;
  height: 100%;
  overflow: auto;
}
.main {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.main-spinning {
  width: 100%;
  height: 100%;
  padding: 0px 20px 20px 20px;
  overflow: auto;
}
.main-show {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: rgba(12, 12, 12, 0.3);
  letter-spacing: 5px;
  white-space: nowrap;
}
.info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-size: 14px;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
}
.info-input {
  width: 120px;
  margin-right: 15px;
}
.info-button {
  width: 80px;
  margin: 0px 10px;
}
.info-hidden {
  display: flex;
  height: 60px;
  align-items: center;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 10px;
}
button {
  /* width: 120px; */
  margin: 0px 10px;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.d {
  /* display: flex; */
  position: relative;
}
.c {
  position: absolute;
  width: 600px;
}
</style>